<template>
    <div class="login-info-card">

        <div
            class="login-box"
            v-show="!loginStatus"
        >

            <div class="top-box">
                <div
                    v-for="(item,index) in computedTypeList"
                    :key="index"
                    class="login-type"
                >
                    <span v-if="item.lineShow">|</span>
                    <a
                        :class="[loginType == item.key ? 'bht_active' : '']"
                        @click="chageLoginType(item)"
                    >
                        {{item.label}}
                    </a>
                </div>
            </div>

            <div class="bottom-box">
                <div class="content">

                    <password-login v-if="loginType === 'password'"></password-login>

                    <phone-login v-if="loginType === 'phone'"></phone-login>

                    <wx-login v-if="loginType === 'wx'"></wx-login>

                    <div
                        class="login-box-btns"
                        v-if="['password', 'phone'].includes(loginType)"
                    >
                        <el-button type="text" @click="forgetPsw">忘记密码</el-button>
                        <el-button type="text" @click="goto('/web/register.html')">用户注册</el-button>
                    </div>
                </div>

            </div>
        </div>

        <div
            class="logined"
            v-show="loginStatus"
        >

            <div class="top-box">
                用户中心
            </div>

            <div class="bottom-box">
                <div class="content">
                    <div class="user">
                        欢迎 {{ loginUserMobile || ''}} 来到{{ webInfo.webName }}
                    </div>

                    <div class="logined-btns">
                        <el-button
                            type="primary"
                            @click="myGuaranteeHandler"
                        >我的保函</el-button>
                        <el-button
                            type="primary"
                            @click="selectGuaranteeHandler"
                        >申请保函</el-button>
                    </div>

                    <div class="layout">
                        <el-button
                            type="text"
                            @click="layoutHandler"
                        >退出登录</el-button>
                    </div>
                </div>
            </div>
        </div>

        <DialogBhType v-model="selectGuaranteeDialogStatus"></DialogBhType>
        <DialogForgetPsw v-model="findDialogVisible"></DialogForgetPsw>
    </div>
</template>

<script>
import { mapGetters, mapMutations, } from 'vuex';

import LOGIN_SUCCESS from '../mixins/login-success.js';
import PAGE_MIXIN from '@mixins/page.js';

import DialogBhType from '@components/sys/dialog-bh-type.vue';
import DialogForgetPsw from '@components/sys/dialog-forget-psw.vue';

//登录
import passwordLogin from '../components/passwordLogin';
import phoneLogin from '../components/phoneLogin';
import wxLogin from '../components/wxLogin';

export default {
    mixins: [LOGIN_SUCCESS,PAGE_MIXIN],
    components: {
        DialogBhType,
        DialogForgetPsw,
        passwordLogin,
        phoneLogin,
        wxLogin,
    },
    props: {
        layout: {
            type: String,
            default: 'password,phone,wx',
        },
    },
    data() {
        return {
            loginType: 'password',

            loginTypeList: [
                {
                    label: '密码',
                    key: 'password',
                    lineShow: false,
                    show: () => /password/.test(this.layout),
                },
                {
                    label: '手机号',
                    key: 'phone',
                    lineShow: true,
                    show: () =>  /phone/.test(this.layout),
                },
                //微信扫码方式判断 只有保函通平台显示
                {
                    label: '微信扫码',
                    key: 'wx',
                    lineShow: true,
                    // show: () =>  /wx/.test(this.layout),
                    show: () =>  (/wx/.test(this.layout) && this.webInfo.id == 'bht'),
                },
            ],

            selectGuaranteeDialogStatus: false,
            findDialogVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            getUserinfo: 'userinfo/getUserinfo',
            loginStatus: 'userinfo/loginStatus',
        }),
        loginUserMobile() {
            return this.getUserinfo?.loginUser?.mobile;
        },
        computedTypeList() {
            return this.loginTypeList.filter(item => item.show());
        },
    },
    methods: {
        ...mapMutations({
            userinfoUpdata: 'userinfo/userinfoUpdata',
        }),
        chageLoginType(item) {
            this.loginType = item.key;
        },

        myGuaranteeHandler() {
            // this.goto({
            //     path: '/sys/ordermg/list',
            // });

            this.loginSuccess();
        },

        forgetPsw() {
            this.findDialogVisible = true;
        },

        selectGuaranteeHandler() {
            this.selectGuaranteeDialogStatus = true;
        },

        //退出登录
        layoutHandler() {
            this.$store.dispatch('userinfo/logout', () => {
                showMsg('退出成功', 'success');
                window.sessionStorage.clear();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.login-info-card {
    display: flex;

    .login-box,
    .logined {
        z-index: 99;
        width: 350px;
        height: 350px;
        background: white;
    }

    .top-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        border-bottom: 1px solid $infoColor3;

        .login-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;

            span {
                font-size: 20px;
                padding: 0 16px;
            }

            a {
                cursor: pointer;
            }

            a:hover {
                color: $primaryColor;
            }
        }
    }

    .logined .top-box {
        font-size: 18px;
        color: $primaryColor;
    }

    .bottom-box {
        height: calc(100% - 60px);
        padding: 0 20px;
        display: flex;
        align-items: center;

        .content {
            width: 100%;
        }

        .login-box-btns {
            text-align: right;
            .el-button {
                padding-bottom: 0px !important;
            }
        }

        .user {
            font-size: 18px;
            text-align: center;
            padding: 40px 0 50px;
        }

        .logined-btns {
            text-align: center;
            padding: 0 0 52px 0;
        }

        .layout {
            text-align: right;
            .el-button {
                padding: 0px !important;
            }
        }
    }
}
</style>